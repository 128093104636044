import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header } from "../../components";
import { getCompanyHeaderMapper, getCompanyMapper } from "./mapper";
import { companySelector } from "./redux/slice";

const Companys = ({ getCompanys, deleteCompany }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { companys,total } = useSelector(companySelector)
  

  const [paginationModel, setPaginationModel] = useState({
    page:0,
    pageSize:100
  })
  const [search, setSearch] = useState('')


  const getSearchResults=()=>{

    setPaginationModel({...paginationModel,page:0})
    getCompanys({limit:paginationModel.pageSize,page:paginationModel.page+1,search})

  }

  // useEffect(() => {
  //   getCustomers({account_type:'Customer',limit:paginationModel.pageSize,page:paginationModel.page+1})
  // }, [paginationModel.page,paginationModel.pageSize])
  


  useEffect(() => {
    if(search)
    {
      getCompanys({account_type:'Company',limit:paginationModel.pageSize,page:1,search})

    }
    else
    getCompanys({account_type:'Company',limit:paginationModel.pageSize,page:paginationModel.page+1})

  }, [search,paginationModel.page,paginationModel.pageSize])

  useEffect(() => {
   setPaginationModel({...paginationModel,page:0})
  }, [search])

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header viewAll={true}  title="Companys" subtitle={"Welcome to your " + "companys"} />
      </Box>
      <Button sx={{ bgcolor: '#0066CC' }} variant='contained' startIcon={<AddIcon />} onClick={() => { navigate('/companys/add') }}>{t('Add')}</Button>
      <GlobalBoxContainer>
        <DataGridTable total={total} paginationModel={paginationModel} setPaginationModel={setPaginationModel} rows={getCompanyMapper(companys)} columns={getCompanyHeaderMapper(companys).concat(Actions(deleteCompany, "edit/")).reverse()} />
      </GlobalBoxContainer>
    </Box>
  );
};

export default Companys;