import { createSlice } from "@reduxjs/toolkit"
import { addCompany, deleteCompany, editCompany, getCompanys, getCompany } from "./service"
import { fulfilledReducer, pendingReducer, rejectedReducer } from "../../../redux/globalReducer"
const initialState = {
    status: '',
    logging: false,
    companys:[],
    company:{},
    total:0
}
export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        delete(state,action){
            return {
                ...state,companys:[...state.companys].filter((company)=>company.id!=action.payload)
            }
       
        }
    },
    extraReducers: {
        [getCompanys.pending]: pendingReducer,
        [getCompanys.fulfilled]: (state, { payload }) => {
            state.companys= payload?payload:[]
            state.total=payload.total?payload.total:state.total
            state.status = 'fulfilled'
            state.logging = false
        },
        [getCompanys.rejected]: rejectedReducer,

        [deleteCompany.pending]: pendingReducer,
        [deleteCompany.fulfilled]: fulfilledReducer,
        [deleteCompany.rejected]: rejectedReducer,

        
        [addCompany.pending]: pendingReducer,
        [addCompany.fulfilled]: fulfilledReducer,
        [addCompany.rejected]: rejectedReducer,

        [getCompany.pending]: pendingReducer,
        [getCompany.fulfilled]: (state, { payload }) => {
           
            state.company= payload?payload:{}
            state.status = 'fulfilled'
            state.logging = false
        },
        [getCompany.rejected]: pendingReducer,

        [editCompany.pending]: pendingReducer,
        [editCompany.fulfilled]: fulfilledReducer,
        [editCompany.rejected]: rejectedReducer,
 
    }
})

export const companySelector = (state) => state.company;