import React, { useEffect, useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from "react-i18next";
import { GlobalBoxContainer } from "../../Styles/Styles";
import { Actions, DataGridTable, Header, ReceivableDetails } from "../../components";
import { getCustomerHeaderMapper, getCustomerMapper, getReceivableDetails, getTicketHeaderMapper, getTicketMapper, getTransactionHeaderMapper, getTransactionMapper } from "./mapper";
import { customerSelector } from "./redux/slice";
import { deleteNullValues, handleScroll, translateArrayOfObjects } from "../../services/functions";
import { t } from "i18next";
import ReactToPrint from "react-to-print";
import { useState } from "react";
import { Filter } from "../../components/Filter";
import SearchField from "../../components/SearchField";
import { GENERAL_DAILY_FILTER_FORM } from "../generalDaily/form";
import Papa from 'papaparse';
const AccountStatement = ({ getAccountStatment,errors,setErrors }) => {
  const {id}=useParams()
  const componentRef = useRef()
  const { accountStatement,tickets_total,transaction_total } = useSelector(customerSelector)
  const [transactionPaginationModel, setTransactionPaginationModel] = useState({
    page:0,
    pageSize:100

  })

  const handleExport = () => {
    // console.log(translateArrayOfObjects(getTicketMapper(accountStatement.tickets)));
    const ticketsData = Papa.unparse(translateArrayOfObjects(getTicketMapper(accountStatement.tickets)), {
      header: true,
      skipEmptyLines: true
    });

    const transactionsData = Papa.unparse(translateArrayOfObjects(getTransactionMapper(accountStatement.transactions)), {
      header: true,
      skipEmptyLines: true
    });
  
    
    const bom = "\uFEFF";

    if(ticketsData){
      const blob = new Blob([bom + ticketsData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', 'تذاكر');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  

    if(transactionsData)
      {
        const blob2 = new Blob([bom + transactionsData], { type: 'text/csv;charset=utf-8;' });

        const link2 = document.createElement('a');
        const url2 = URL.createObjectURL(blob2);
        link2.href = url2;
        link2.setAttribute('download', 'دفعات');
        document.body.appendChild(link2);
        link2.click();
        document.body.removeChild(link2);
      }
    
  };

  const [ticketPaginationModel, setTicketPaginationModel] = useState({
    page:0,
    pageSize:100

  })

  const [open, setOpen] = useState(false);

  const [filter, setFilter] = useState({
    min_date:"",
    max_date:""
  })

  const [search, setSearch] = useState('')

  const handleFilter=()=>{
    setTransactionPaginationModel({...transactionPaginationModel,page:1})
    setTicketPaginationModel({...ticketPaginationModel,page:1})

    setOpen(false)
    // getAccountStatment(id,{search:search?search:undefined,transactions_page:transactionPaginationModel.page+1,tickets_page:ticketPaginationModel.page+1,transactions_limit:transactionPaginationModel.pageSize,tickets_limit:ticketPaginationModel.pageSize,min_date:filter.min_date?filter.min_date:undefined,max_date:filter.max_date?filter.max_date:undefined})
    
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleFilterOpen = (item) => {
    // //console.log(deleteSubmit);
  
    handleClickOpen();
  };

  const getSearchResults=()=>{

    // setPaginationModel({...paginationModel,page:0})
    setTransactionPaginationModel({...transactionPaginationModel,page:0})
    setTicketPaginationModel({...ticketPaginationModel,page:0})

    getAccountStatment(id,{search:search?search:undefined,transactions_page:1,tickets_page:1,transactions_limit:transactionPaginationModel.pageSize,tickets_limit:ticketPaginationModel.pageSize})


  }

  useEffect(() => {
    getAccountStatment(id,{search:search?search:undefined,transactions_page:transactionPaginationModel.page+1,tickets_page:ticketPaginationModel.page+1,transactions_limit:transactionPaginationModel.pageSize,tickets_limit:ticketPaginationModel.pageSize,min_date:filter.min_date?filter.min_date:undefined,max_date:filter.max_date?filter.max_date:undefined})

  }, [transactionPaginationModel.page,transactionPaginationModel.pageSize])

  useEffect(() => {
    getAccountStatment(id,{search:search?search:undefined,transactions_page:transactionPaginationModel.page+1,tickets_page:ticketPaginationModel.page+1,transactions_limit:transactionPaginationModel.pageSize,tickets_limit:ticketPaginationModel.pageSize})

  }, [ticketPaginationModel.page,ticketPaginationModel.pageSize])

  useEffect(() => {
    if(search)
    getAccountStatment(id,{search,transactions_page:transactionPaginationModel.page+1,tickets_page:ticketPaginationModel.page+1,transactions_limit:transactionPaginationModel.pageSize,tickets_limit:ticketPaginationModel.pageSize})
    else
    getAccountStatment(id,{transactions_page:transactionPaginationModel.page+1,tickets_page:ticketPaginationModel.page+1,transactions_limit:transactionPaginationModel.pageSize,tickets_limit:ticketPaginationModel.pageSize})

  }, [search])
  
  





 


 

  return (
    <div>
      {/* <div onMouseOver={handleScroll}>
      <ReactToPrint
       
        trigger={() => (
        
        )}
        content={() => componentRef.current}
      />
      </div> */}
      <Button onClick={handleExport}  sx={{marginRight:'8%'}} variant="contained" color="primary">
            {t("Print Account Statement")}
      </Button>

<Link to='/transactions/add'>
      <Button sx={{marginRight:'2%'}}>
        {t('Create Transaction')}
      </Button>
   </Link>
    
    <Box ref={componentRef}   m="20px">
    <Filter open={open} setOpen={setOpen}  data={filter} errors={errors} setErrors={setErrors} setData={setFilter} form={GENERAL_DAILY_FILTER_FORM} handleFilter={handleFilter}  />

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Account statement" subtitle={"Welcome to your " + "account statement"} />
      </Box>
      <Button sx={{ bgcolor: '#0066cc',marginRight:'2%',top:'10px'}} variant='contained'  onClick={ handleFilterOpen}>{t('Filter')}</Button>
      <SearchField getSearchResults={getSearchResults}  label={"Search Customer name or ticket number"} placeholder={'Enter Customer name or ticket number'} setValue={setSearch} value={search} />
      <GlobalBoxContainer>
        <ReceivableDetails data={deleteNullValues(getReceivableDetails(accountStatement.receivable_details)    ) }  />
        
    {   
     accountStatement.tickets?.length>0&&
     <div>
      <h1 style={{color:"#535AC8"}} align="center" >{t('Tickets')}</h1>
     <DataGridTable paginationModel={ticketPaginationModel} setPaginationModel={setTicketPaginationModel} total={tickets_total} rows={getTicketMapper(accountStatement.tickets)} columns={getTicketHeaderMapper(accountStatement.tickets).reverse()} />
     </div>
     }
{ 
 accountStatement.transactions?.length>0&&   
 <div>
 <h1 style={{color:"#535AC8"}} align="center" >{t('Transactions')}</h1>
<DataGridTable paginationModel={transactionPaginationModel} setPaginationModel={setTransactionPaginationModel} total={transaction_total} rows={getTransactionMapper(accountStatement.transactions)} columns={getTransactionHeaderMapper(accountStatement.transactions).reverse()} />
</div>
}
      </GlobalBoxContainer>
    </Box>
    </div>
  );
  
};

export default AccountStatement;