import Joi from "joi-browser";

export const addTicketSchema = {
    travel: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter  travel",
      };
    }),

    description: Joi.any(),
    // commission: Joi.any(), 


    release_date: Joi.any()
    .required()
    .error(() => {
      return {
        message: "Please choose release date",
      };
    }),

    supplier_deal_type: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please choose supplier deal type",
      };
    }),

  

    ticket_number: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Please enter a ticket number",
      };
    }),

    customer_id: Joi.number().allow('')
    .error(() => {
      return {
        message: "Please enter a customer id",
      };
    }),

    customer_name: Joi.any().allow(null)
    .error(() => {
      return {
        message: "Please enter a customer name",
      };
    }),

    supplier_id: Joi.string()
    .error(() => {
      return {
        message: "Please choose a supplier",
      };
    }),

    
    employee_id: Joi.any().allow(null)
    .error(() => {
      return {
        message: "Please choose a employee",
      };
    }),


    company_id: Joi.string()
    .error(() => {
      return {
        message: "Please choose a company",
      };
    }),

    currency_id: Joi.string()
    .error(() => {
      return {
        message: "Please choose a currency",
      };
    }),

    ticket_price: Joi.string()
    .error(() => {
      return {
        message: "Please enter a ticket price",
      };
    }),

 

    customer_price: Joi.string()
    .error(() => {
      return {
        message: "Please enter a customer ticket price  ",
      };
    }),

    supplier_commission: Joi.string()
    .error(() => {
      return {
        message: "Please enter a supplier commission",
      };
    }),

    ticket_fare: Joi.when('supplier_deal_type',{
      is:'RATIO',
      then: Joi.any().required(),
      otherwise:Joi.any() 
    })
    .error(() => {
      return {
        message: "Please enter a ticket price fare in doller or price in syrian pounds ",
      };
    }),

 

    pay_price_dollar: Joi.any()
    .error(() => {
      return {
        message: "Please enter a paid price in doller ",
      };
    }),

    pay_price_sy: Joi.any()
    .error(() => {
      return {
        message: "Please enter a paid price in syria",
      };
    }),

};

export const addReturnedTicketSchema = {


  description: Joi.any(),





  ticket_number: Joi.any()
  .required()
  .error(() => {
    return {
      message: "Please enter a ticket number",
    };
  }),

  customer_id: Joi.any().allow(null)
  .error(() => {
    return {
      message: "Please enter a customer id",
    };
  }),

  

  supplier_id: Joi.any().allow(null)
  .error(() => {
    return {
      message: "Please choose a supplier",
    };
  }),
  returned_amount_supplier_sy: Joi.any()
  .error(() => {
    return {
      message: "Please enter price in syrian pounds ",
    };
  }),

   returned_amount_supplier_dollar: Joi.when('returned_amount_supplier_sy',{
    is:Joi.number(),
    then: Joi.any(),
    otherwise:Joi.number().required() 
  })
  .error(() => {
    return {
      message: "Please enter price in dollar ",
    };
  }),

  returned_amount_customer_sy: Joi.any()
  .error(() => {
    return {
      message: "Please enter price in syrian pounds ",
    };
  }),

  returned_amount_customer_dollar: Joi.when('returned_amount_customer_sy',{
    is:Joi.number(),
    then: Joi.any(),
    otherwise:Joi.number().required() 
  })
  .error(() => {
    return {
      message: "Please enter price in dollar ",
    };
  }),

 



 

 

 

 



};

export const editTicketSchema = {
    travel: Joi.any()
    .error(() => {
      return {
        message: "Please enter  travel",
      };
    }),

    customer_price: Joi.any()
    .error(() => {
      return {
        message: "Please enter a customer ticket price  ",
      };
    }),

    description: Joi.any(),

    release_date: Joi.any()
    .required()
    .error(() => {
      return {
        message: "Please choose release date",
      };
    }),

    rest_amount: Joi.any()
    .error(() => {
      return {
        message: "Please enter a rest amount  ",
      };
    }),

    ticket_price: Joi.any()
    .error(() => {
      return {
        message: "Please enter a ticket price",
      };
    }),

    ticket_price_fare: Joi.any()
    .error(() => {
      return {
        message: "Please enter a ticket price fare",
      };
    }),

    payment_price_to_supplier: Joi.any()
    .error(() => {
      return {
        message: "Please enter a payment price to supplier",
      };
    }),



  

   
};

export const refundTicketSchema = {

  id: Joi.number()
  .error(() => {
    return {
      message: "Please enter id",
    };
  }),
  customer_fine: Joi.any()
  .error(() => {
    return {
      message: "Please enter a customer fine",
    };
  }),

  supplier_fine: Joi.any()
  .error(() => {
    return {
      message: "Please enter a supplier fine",
    };
  }),

 

};

export const reissueTicketSchema = {

  id: Joi.number()
  .error(() => {
    return {
      message: "Please enter id",
    };
  }),
  travel: Joi.any()
  .required()
  .error(() => {
    return {
      message: "Please enter  travel",
    };
  }),



  description: Joi.any(),

  release_date: Joi.any()
  .required()
  .error(() => {
    return {
      message: "Please choose release date",
    };
  }),
  customer_fine: Joi.any()
  .error(() => {
    return {
      message: "Please enter a customer fine",
    };
  }),

  supplier_fine: Joi.any()
  .error(() => {
    return {
      message: "Please enter a supplier fine",
    };
  }),

 

};