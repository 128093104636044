import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useMediaQuery} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Header } from "../../components";
import { ADD_TRANSACTION_FORM, ADD_TRANSACTION_FORM_WITHOUT_ACCOUNT } from "./form";
import { useSelector } from "react-redux";
import { customerSelector } from "../customer/redux/slice";
import { getCustomerData } from "../../services/functions";
import { companySelector } from "../company/redux/slice";

const currentDate = new Date();

const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');

const formattedDate = `${year}-${month}-${day}`;
const accountTypes=[
  { id:"Customer",
    value:"Customer"},


    { id:"Supplier",
    value:"Supplier"},

    { id:"Expense",
    value:"Expense"},


]

const accountTypesWithoutExpence=[
  { id:"Customer",
    value:"Customer"},


    { id:"Supplier",
    value:"Supplier"},




]

const AddTransaction = ({addTransaction,errors,setErrors,getAccounts}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {t}=useTranslation()
  const {companys:accounts}=useSelector(companySelector)
  const [transaction, setTransaction] = useState({
    account_id:"",
    account_name:"",
    pay_price_sy:"",
    pay_price_dollar:"",
    description:'',
    account_type:"Customer",
    date:formattedDate,
    reverse_transaction:false,
    bank_transfer:false
  })
   const [fieldsData, setFieldsData] = useState({
  })

  const [selectData, setSelectData] = useState({
    account_name:[],
    accounts:[],
    account_type:[]
  })
  
  const handleFormSubmit = () => {
    if(transaction.account_name){
      transaction['account_id']=''
    }
    delete transaction['customer_id']
    addTransaction(transaction)
  };
  const handleChange=(event)=>{
    
      const {name,value}=event.target
      
      setTransaction({...transaction,[name]:value})
          setErrors((prevErrors)=>(
        {...prevErrors,
        [name]:''
        }
    ))
  }

  const handleChangeSwitch=(event)=>{
    const {name,checked}=event.target
    if(name=='bank_transfer'){
      setTransaction({...transaction,[name]:checked,['reverse_transaction']:false})
    }
    else if(name=='reverse_transaction'){
      setTransaction({...transaction,[name]:checked,['bank_transfer']:false})
    }
    else{
      setTransaction({...transaction,[name]:checked})
      setErrors((prevErrors)=>(
        {...prevErrors,
        [name]:''
        }
    ))
    }
 
}

const handleColor=(color)=>{
     setTransaction({...transaction,color:color.hex})
        setErrors((prevErrors)=>(
      {...prevErrors,
      color:''
      }
  ))
}

const handleChangeField=(event,itemName,index)=>{
    const {name,value}=event.target
    //console.log(name,value,index);
    const fields=transaction[itemName]
    fields[index][name]=value
    setTransaction({...transaction,[itemName]:fields})
}
const addField=(name)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]+1
    }
))
    const fields=transaction[name]
    fields.push({})
    setTransaction((prevTransaction)=>(
      {...prevTransaction,
      [name]:fields
      }
  ))

}

const deleteField=(name,index)=>{
  setFieldsData((prevField)=>(
    {...prevField,
    [name]:prevField[name]-1
    }
))
    const fields=transaction[name]
    fields.splice(index,1)
    setTransaction({...transaction,[name]:fields})
}
const submitSearchCustomer = (value) => {
  console.log(value);
  setTransaction({...transaction,account_name:value,account_id:''})
  setErrors((prevErrors) => ({ ...prevErrors, account_name: "" }));
};

const submitCustomer = (cus) => {
  
  const customer = cus;
  console.log(customer);
  const id = customer?.id;
  const name=customer?.name
  setTransaction({...transaction,account_id:id,account_name:name})
  setErrors((prevErrors) => ({ ...prevErrors, account_name: "" }));

};
console.log(transaction);
useEffect(() => {
  //console.log(transaction.account_type);
  getAccounts({account_type:transaction.account_type})
  setTransaction({...transaction,account_name:''})
}, [transaction.account_type])

useEffect(() => {
  getAccounts({account_type:transaction.account_type})
}, [])

useEffect(() => {
 setSelectData({
  account_name:getCustomerData(accounts),
  accounts:getCustomerData(accounts),
  
  account_type:accountTypes
 })
 if(transaction.account_type=='Expense'){
  //console.log(getCustomerData(accounts));
    setTransaction({...transaction,account_name:getCustomerData(accounts)[0]?.label})
 }
}, [accounts])

useEffect(() => {
  if(transaction.reverse_transaction||transaction.bank_transfer)
  {
    setSelectData({...selectData,account_type:accountTypesWithoutExpence})
  }
  else 
  {
    setSelectData({...selectData,account_type:accountTypes})

  }
}, [transaction.reverse_transaction,transaction.bank_transfer])



//console.log(transaction);
  return (
    <Box m="20px">
      <Header title="Transaction" subtitle="a New Transaction" />
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
           
            <Form     submitSearchAutoComplete={submitSearchCustomer}
          submitAutoComplete={submitCustomer} setValue={setTransaction} selectData={selectData} handleColor={handleColor} handleChangeSwitch={handleChangeSwitch}  errors={errors} data={transaction}  handleChange={handleChange} type='add' form={transaction.account_type!='Expense'?ADD_TRANSACTION_FORM:ADD_TRANSACTION_FORM_WITHOUT_ACCOUNT}   />
            
          </Box>

          <Box display="flex" justifyContent="end" mt="20px">
            <Button onClick={()=>{handleFormSubmit()}} type="submit" color="secondary" variant="contained">
              {t("New Transaction")}
            </Button>
          </Box>
     
  
    </Box>
  );
};

export default AddTransaction;
