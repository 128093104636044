import {  TextField } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import GeneralSelect from './GeneralSelect';
import GeneralSwitch from './GeneralSwitch';
import { ParagraphErrorStyle } from '../Styles/Styles';
import GeneralColorPicker from './GeneralColorPicker';
import Fields from './Fields';
import VarientFields from './VarientFields';
import TextInput from './TextInput';
import AutocompleteWithIdName from './AutoComplete';


function Form({form,data,selectData,handleChange,errors,handleChangeSwitch,handleColor,handleChangeField,fieldsData,addField,deleteField,handleChangeVarientField,setValue,submitAutoComplete,submitSearchAutoComplete}) {
 
  
    
  console.log(form);
  const {t}=useTranslation()
  return form.map(
        (item) =>
          item.feild == "textField" ? (
            <div style={{ gridColumn: "span "+item.grid }}>
            <TextInput   defaultValue='' value={data[item.name]} handleChange={handleChange} label={item.label} name={item.name} type={item.type} multiline={item.multiline} rows={item.rows}/>
            {/* <TextField
              fullWidth
              variant="filled"
              type={item.type}
              label={item.label}
              // onBlur={handleBlur}
              onChange={handleChange}
              value={data[item.name]}
              name={item.name}
              InputProps={{
                style: { backgroundColor: '#fff',borderRadius:'12px',border:'1px solid' },
                disableUnderline: true
              }}
              InputLabelProps={{
                
                style: { color: '#000',fontSize:'16px',fontWeight:'bold' },
              }}
            /> */}
               {errors[item.name]&&<ParagraphErrorStyle>{t(errors[item.name])}</ParagraphErrorStyle>}
            </div>
          ):item.feild=='multiSelect'?
          
  
            <div style={{ gridColumn: "span "+item.grid }}>
        <GeneralSelect data={data} handleChange={handleChange} item={item} multiple={true} selectData={selectData}  />
        {errors?.[item.name]&&<ParagraphErrorStyle>{t(errors[item.name])}</ParagraphErrorStyle>}

        </div>
       
        :item.feild == "select"?
        <div style={{ gridColumn: "span "+item.grid }}>
        <GeneralSelect data={data} handleChange={handleChange} item={item} multiple={false} selectData={selectData}  />
        {errors?.[item.name]&&<ParagraphErrorStyle>{t(errors[item.name])}</ParagraphErrorStyle>}

      </div>:item.feild =='switch'?<GeneralSwitch color='secondary' data={data} handleChange={handleChangeSwitch} item={item}  />
      :item.feild=='color'?
      <div style={{ gridColumn: "span "+item.grid }}>
      <GeneralColorPicker color={data[item.name]}  handleColor={handleColor} label={item.label} labelColor={item.labelColor} labelVarient={item.labelVarient}   />
      {errors?.[item.name]&&<ParagraphErrorStyle>{t(errors[item.name])}</ParagraphErrorStyle>}

      </div>
      :item.feild== 'arrayOfFields'?
              <div style={{ gridColumn: "span "+item.grid }}>
              <Fields  count={fieldsData[item.name]} item={item} addField={addField} data={data} deleteField={deleteField} handleChangeField={handleChangeField} />
              {errors[item.name]&&<ParagraphErrorStyle>{t(errors[item.name])}</ParagraphErrorStyle>}
              </div>
      :item.feild== 'arrayOfSelectAndFields'?
      <div style={{ gridColumn: "span "+item.grid }}>
      <VarientFields addField={addField} count={fieldsData[item.name]} data={data} deleteField={deleteField} handleChangeField={handleChangeVarientField} item={item} selectData={selectData} />
      {errors[item.name]&&<ParagraphErrorStyle>{t(errors[item.name])}</ParagraphErrorStyle>}
      </div>
      :item.feild=="autoComplete"?
      <div style={{ gridColumn: "span "+item.grid }}>
      <AutocompleteWithIdName   item={item} setValue={setValue} submit={submitAutoComplete} submitSearch={submitSearchAutoComplete} suggestions={selectData} value={data} />
      {errors[item.name]&&<ParagraphErrorStyle>{t(errors[item.name])}</ParagraphErrorStyle>}
      
      </div>:""
      )}
      
  


export default Form